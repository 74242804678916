import { AxiosError } from "axios";
import { ApiRequest } from "@/services/BaseApi";

export const fetcher = async <T>(
  url: string,
  method: "GET" | "POST" | "DELETE" | "PUT" = "GET",
  data: any = null
): Promise<T> => {
  const axiosInstance = ApiRequest();

  try {
    let config = {};

    if (method === "GET" && data) {
      config = { params: data };
    } else if (method === "POST" && data) {
      config = { headers: { "Content-Type": "multipart/form-data" } };
    }
    const response = await axiosInstance.request<T>({
      url,
      method,
      data: method !== "GET" ? data : undefined,
      ...config,
    });

    return response.data;
  } catch (error) {
    throw (error as AxiosError).response?.data || error;
  }
};
