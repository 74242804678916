import React, { useContext } from "react";
import { SheetContext } from "@/components/shareds/Sheet/Sheet";
interface SheetContentProps {
  children:
    | React.ReactNode
    | ((props: { close: () => void }) => React.ReactNode);
}
const SheetContent = ({ children }: SheetContentProps) => {
  const { close }: { close: () => void } = useContext(SheetContext) || {};
  const isFunctionChild = typeof children === "function";
  return (
    <div className="flex-1 px-2 py-2 w-full overflow-auto">
      {isFunctionChild
        ? children && (children as Function)({ close })
        : children}
    </div>
  );
};

export default SheetContent;
