"use client";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { motion, AnimatePresence } from "framer-motion";
import SheetContent from "@/components/shareds/Sheet/SheetContent";
import SheetHeader from "@/components/shareds/Sheet/SheetHeader";
import SheetFooter from "@/components/shareds/Sheet/SheetFooter";
import ReactDOM from "react-dom";
import clsx from "clsx";

type ISheetProps = {
  children: React.ReactNode;
  size?: "sm" | "md" | "lg";
  isOpen: boolean;
  setIsOpen: any;
  className?: string;
  isClickOutside?: boolean;
};
type ISheetContext = {
  open: boolean;
  close: () => void;
};
export const SheetContext = createContext<ISheetContext>({} as ISheetContext);
const Sheet = (props: ISheetProps) => {
  const {
    children,
    isOpen = false,
    setIsOpen,
    size = "sm",
    className = "",
    isClickOutside = true,
  } = props;
  const [bgOverlay, setBgOverlay] = React.useState(false);
  const sheetRef = useRef<HTMLDivElement>(null);
  const sheetBgRef = useRef<HTMLDivElement>(null);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  const value = useMemo(() => ({ open: isOpen, close }), [isOpen, close]);

  useEffect(() => {
    if(isClickOutside) {
    const clickHandle = (e: any) => {
      if (
        sheetBgRef.current &&
        sheetRef.current &&
        !sheetRef.current?.contains(e.target as Node) &&
        sheetBgRef.current?.contains(e.target as Node)
      ) {
        const length = document.getElementsByClassName("sheet").length;
        if (length === 1 && isOpen) {
          close();
        }
      }
    };
    document.addEventListener("click", clickHandle);
    return () => {
      document.removeEventListener("click", clickHandle);
    }
  }
  }, [isOpen, close]);

  useEffect(() => {
    const length = document.getElementsByClassName("sheet").length;
    if (length === 1) {
      setBgOverlay(true);
    } else {
      setBgOverlay(false);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <SheetContext.Provider value={value}>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="sheetBg"
            ref={sheetBgRef}
            initial={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`fixed sheet inset-0 z-[999999] ${
              bgOverlay ? "bg-black/70 backdrop-blur-sm" : ""
            }`}
          >
            <motion.aside
              ref={sheetRef}
              key="sheet"
              initial={{ opacity: 0, right: "-600px" }}
              transition={{ ease: "easeInOut", duration: 0.6 }}
              animate={{ opacity: 1, right: "8px" }}
              exit={{ opacity: 0, right: "-600px" }}
              className={clsx(
                "max-h-dvh h-[calc(100%_-_16px)] bg-extended_neutral-N0 rounded-page tablet:rounded-page-md laptop:rounded-page fixed z-50 top-[8px] flex flex-col justify-between items-start",
                { "w-[300px]": size == "sm" },
                { "w-[450px]": size == "md" },
                { "w-[600px]": size == "lg" },
                className
              )}
            >
              {children}
            </motion.aside>
          </motion.div>
        )}
      </AnimatePresence>
    </SheetContext.Provider>,
    document.body
  );
};

Sheet.Content = SheetContent;
Sheet.Header = SheetHeader;
Sheet.Footer = SheetFooter;
export default Sheet;
