import React, {useContext} from 'react';
import {SheetContext} from "@/components/shareds/Sheet/Sheet";
interface SheetHeaderProps {
    children: React.ReactNode | ((props: { close: () => void }) => React.ReactNode);
}
const SheetHeader = ({children} : SheetHeaderProps) => {
    const { close }: { close: () => void } = useContext(SheetContext) || {};
    const isFunctionChild = typeof children === 'function';
    return (
        <div className="border-b border-neutral-N600/10 w-full py-2 px-2">
            {isFunctionChild ? children && (children as Function)({ close }) : children}
        </div>
    );
};

export default SheetHeader;