'use client'
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react'

type Props = {}

const MobileApp = (props: Props) => {
    const [isAppVisible, setIsAppVisible] = useState(false);
    const [appLink, setAppLink] = useState("");
    var googleStore = "https://play.google.com/store/apps/details?id=app.novadan.android";
    var appleStore = "https://apps.apple.com/tr/app/novadan/id6477601238";

    useEffect(() => {
      if (/Android|webOS|BlackBerry|IEMobile|CriOS|Opera Mini/i.test(navigator.userAgent)) {
        setIsAppVisible(true);
      } 
      else {
        setIsAppVisible(false);
      }
      
        if(/CriOS/i.test(navigator.userAgent)) {
          const meta = document.createElement('meta');
          meta.name = 'apple-itunes-app';
          meta.content = 'app-id=6477601238, app-argument=https://apps.apple.com/tr/app/novadan/id6477601238';
          document.head.appendChild(meta);
          setAppLink(appleStore);
        }
        else {
          setAppLink(googleStore);
        }
    }, []);

    return <div id='mobile-app-download' className={clsx(" bottom-0 w-full !z-[9999999999] py-6 px-4 bg-[url('/images/play-bg.png')]  flex flex-col gap-3", {"fixed": isAppVisible, "hidden": !isAppVisible})}>
    <button onClick={() => setIsAppVisible(false)} className="text-white text-lg absolute right-2 top-1 font-bold p-2"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg></button>
    <div className="flex items-center gap-3 pr-9">
        <Image alt="" height={50} width={50} src="/images/novadan-icon.png" className="w-[41px] h-[41px] object-contain" />
        <h4 className="text-lg text-white font-semibold">Sadece uygulamaya özel fırsatlar seni bekliyor.</h4>
    </div>
    <Link href={appLink} className="flex justify-center bg-white text-indigoNova rounded-lg text-sm font-bold py-3 w-full">Uygulamayı İndir</Link>
    </div>
}

export default MobileApp